<template>
  <section>
    <hero-global title="Colaboración Asociativa"/>
  </section>
  <section class="container p-2-rem">
    <div class="grid-2">
      <div class="grid">
        <p>La colaboración entre las organizaciones, la puesta en común de los recursos y, en ocasiones de los servicios
          que se prestan, el abandono de prácticas centradas con exclusividad en los miembros de la respectiva entidad,
          el abordaje de las posibilidades que cada entorno ofrece, constituyen retos a los que se enfrentan las
          entidades del tercer sector en general y, de manera más concreta, quienes actúan en el ámbito de la
          discapacidad.
        </p>
        <p>Parece que esta relación ínter asociativa se configura como un desafío para las organizaciones. Pero esa
          colaboración no puede ni debe llevarse a cabo de forma indiscriminada. Por ello, el Comité Ejecutivo del CERMI
          ha aprobado un documento sobre principios y estrategias que deben tenerse en cuenta a la hora de afrontar
          dicha colaboración y, por la misma razón, se abre este apartado dedicado a esta cuestión bajo el título
          “Colaboración asociativa”.
        </p>
        <p>En el se pretende recoger documentos y trabajos relacionados con este tema, con el propósito de contribuir a
          la reflexión y al debate entre quienes integran el CERMI. Se trata de generar un espacio abierto que propicie
          el intercambio de información y, en su caso, pueda recoger experiencias que puedan ilustrar a las
          organizaciones que quieren encaminarse por la senda de la colaboración.</p>
      </div>
      <div class="grid text-right">
        <img class="d-block w-80" src="/img/collaborative-association/collaborative-association_1.png"
             alt="Imagen de Colaboración Asociativa" title="Imagen de Colaboración Asociativa">
      </div>
    </div>
    <br/>
    <br/>
  </section>
  <section class="container p-2-rem">
    <div class="shadow p-1-rem">
      <h2 class="title-dest m-top-20-px">Enlaces de interés:</h2>
      <div>
        <ul>
          <li><a href="http://www.coworkingspain.es/magazine/noticias/el-crecimiento-de-la-sociedad-colaborativa">http://www.coworkingspain.es/magazine/noticias/el-crecimiento-de-la-sociedad-colaborativa</a>
          </li>
          <li><a href="#">II BUENAS PRÁCTICAS PRESENTADAS (2018)</a></li>
          <li><a href="#">INFORMACIÓN SOBRE LA I JORNADA SOBRE COLABORACIÓN INTER-ASOCIATIVA DEL CERMI:</a></li>
          <li><a href="#">INFORMACIÓN SOBRE LA II JORNADAS SOBRE COLABORACIÓN INTER-ASOCIATIVA DEL CERMI:</a></li>
          <li><a href="#">Seminario "Tendencias de futuro, oportunidades y retos". Promovido por la Comisión de
            Cooperación e Innovación Asociativas del CERMI Estatal </a></li>
        </ul>
      </div>
    </div>
  </section>
  <section class="container p-2-rem">
    <div class="accordion box-content container shadow">
      <div class="content container w-100">
        <h2 class="title-grand">Documentación</h2>
        <ul class="list-item list">
          <li>
            <input type="checkbox" name="list" id="aspace-1"/>
            <label for="aspace-1">
              <span class="title">Descarga de documentos</span>
            </label>
            <div class="inner-container">
              <ul class="p-2-rem">
                <li class="border-none">
                  <i class="fa fa-file-pdf-o text-primary m-right-1-rem"></i>
                  <a href="/docs/doc-principios-estrategias.doc">Documento de propuestas de principios y estrategias</a>
                </li>
                <li class="border-none">
                  <i class="fa fa-file-pdf-o text-primary m-right-1-rem"></i>
                  <a href="/docs/buenas-practicas-colaboracion-14.doc">Buenas prácticas de Colaboración Asociativa</a>
                </li>
                <li class="border-none">
                  <i class="fa fa-file-pdf-o text-primary m-right-1-rem"></i>
                  <a href="/docs/criterios-colaboracion.doc">Anexo. Criterios de Buenas Prácticas de Colaboración</a>
                </li>
                <li class="border-none">
                  <i class="fa fa-file-pdf-o text-primary m-right-1-rem"></i>
                  <a href="/docs/nuevas-tendencias.pdf" target="_blank">Nuevas tendencias de la innovación social</a>
                </li>
                <li class="border-none">
                  <i class="fa fa-file-pdf-o text-primary m-right-1-rem"></i>
                  <a href="/docs/II-buenas-practicas-2018.docx">II BUENAS PRACTICAS 2018.docx</a>
                </li>
                <li class="border-none">
                  <i class="fa fa-file-pdf-o text-primary m-right-1-rem"></i>
                  <a href="/docs/resumen-jornadas-colaboracion-2018.pdf" target="_blank">RESUMEN II Jornadas Colaboración interasociativa junio 2018.pdf</a>
                </li>
                <li class="border-none">
                  <i class="fa fa-file-pdf-o text-primary m-right-1-rem"></i>
                  <a href="/docs/buenas-practicas-de-las-II-jornadas-colaboracion.docx">BUENAS PRÁCTICAS de las II JORNADAS sobre COLABORACIÓN INTERASOCIATIVA del CERMI.docx</a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>
  <section class="container p-x-2-rem">
    <other-links-interest class="links-interest-box container" />
  </section>
</template>

<script>
import HeroGlobal from "@/components/Global/HeroGlobal";
import OtherLinksInterest from "@/components/Layout/OtherLinksInterest";

export default {
  name: "CollaborativeAssociation",
  components: {OtherLinksInterest, HeroGlobal}
}
</script>

<style scoped lang="scss">
@import "@/styles/colours.scss";

ul {
  list-style: none;

  li {
    padding: 1rem;
    a{
      text-decoration: none;
      color:$blue
    }
  }
}
.box-content {
  background: transparent !important;
  .inner-container{
    padding-left: 0 !important;
  }

  .list-item {
    list-style : none;
    padding    : 15px 0;

    &>li{
      padding: 0;
    }

    label {
      padding   : 0;
      font-size : 22px;

      &:before {
        bottom: 10px !important;
      }

      span {
        font-size    : 20px;
        padding-left : 0 !important;
        color        : black;
      }
    }
  }
}
.border-none{
  border: 0 !important;
}
</style>










